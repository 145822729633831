import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { PATH } from '@constants';
import { tokenAtom } from 'atoms/auth';
import { useDebounce } from 'react-use';
import AuthenticatedLayout from 'Layout/AuthenticatedLayout';
import LoginAreaLayout from 'Layout/LoginAreaLayout';
import CursosPage from 'pages/CursosPage';
import CursoPage from 'pages/CursoPage';
import LoginPage from 'pages/LoginPage';
import SimuladoPage from 'pages/SimuladoPage';
// import TestePage from 'pages/TestePage';
import MainPage from 'pages/MainPage';
import MensagensPage from 'pages/MensagensPage';
import WhatsAppMensagensPage from 'pages/WhatsAppMensagensPage';
import AlertasPage from 'pages/AlertasPage';
import CursoAulasPage from 'pages/CursoAulasPage';
import AulaPage from 'pages/AulaPage';
import SimuladoPerguntasPage from 'pages/SimuladoPerguntasPage';
import RecuperarSenhaPage from 'pages/RecuperarSenhaPage/RecuperarSenhaPage';
import CadastroPage from 'pages/CadastroPage/CadastroPage';
import PedidosPage from 'pages/PedidosPage';
import MeuPerfilPage from 'pages/MeuPerfilPage';
import AoVivoPage from 'pages/AoVivoPage';
import ErrorBoundary from 'components/ErrorBoundary';
import CronogramaPage from 'pages/CronogramaPage/CronogramaPage';
import RedefinirSenhaPage from 'pages/RedefinirSenhaPage/RedefinirSenhaPage';
import AoVivoItemPage from 'pages/AoVivoItemPage';

function InnerRoutes() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route
            element={
              <RequireEmptyToken>
                <LoginAreaLayout />
              </RequireEmptyToken>
            }
          >
            <Route path={PATH.login} element={<LoginPage />} />
            <Route
              path={PATH.recuperarSenha}
              element={<RecuperarSenhaPage />}
            />
            <Route
              path={PATH.redefinirSenha}
              element={<RedefinirSenhaPage />}
            />
            <Route path={PATH.cadastro} element={<CadastroPage />} />
          </Route>
          <Route
            element={
              <RequireAuth>
                <AuthenticatedLayout />
              </RequireAuth>
            }
          >
            <Route path={'/'} element={<MainPage />} />
            {/* <Route path="/teste" element={<TestePage />} /> */}
            <Route path={PATH.cursos} element={<CursosPage />} />
            <Route path={PATH.curso} element={<CursoPage />} />
            <Route path={PATH.cursoAulas} element={<CursoAulasPage />} />
            <Route path={PATH.cronograma} element={<CronogramaPage />} />
            <Route path={PATH.aula} element={<AulaPage />} />
            <Route path={PATH.simulado} element={<SimuladoPage />} />
            <Route
              path={PATH.simuladoPerguntas}
              element={<SimuladoPerguntasPage />}
            />
            <Route path={PATH.mensagens} element={<MensagensPage />} />
            <Route
              path={PATH.whatsappMensagens}
              element={<WhatsAppMensagensPage />}
            />
            <Route path={PATH.notificacoes} element={<AlertasPage />} />
            <Route path={PATH.aovivo} element={<AoVivoPage />} />
            <Route path={PATH.aovivoItem} element={<AoVivoItemPage />} />
            <Route path={PATH.perfil} element={<MeuPerfilPage />} />
            <Route path={PATH.pedidos} element={<PedidosPage />} />
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

function RequireEmptyToken({ children }: { children: JSX.Element }) {
  const token = useRecoilValue(tokenAtom);

  if (token) {
    return <Navigate to="/" replace />;
  }
  return children;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const token = useRecoilValue(tokenAtom);
  const location = useLocation();
  const navigate = useNavigate();

  useDebounce(
    () => {
      if (!token) {
        navigate(PATH.login, {
          state: { from: location },
          replace: true,
        });
      }
    },
    300,
    [token]
  );

  if (!token) {
    return null;
  }
  return children;
}

export default InnerRoutes;
