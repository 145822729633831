import { Box, Link, Paper, useTheme } from '@mui/material';
import logoImage from 'assets/whatsapp-messages-logo.png';
import logoLightImage from 'assets/whatsapp-messages-logo-light.png';
import horarioAtendimentoImage from 'assets/whatsapp-messages-horario-atendimento.png';
import cliqueAquiImage from 'assets/whatsapp-messages-clique-aqui.png';

function WhatsAppMensagensPage() {
  const theme = useTheme();
  return (
    <Paper
      square
      sx={(theme) => ({
        display: 'flex',
        flex: '1 1 auto',
        height: '100%',
        // bgcolor: theme.palette.mode === 'light' ? 'rgb(50,54,53)' : 'background.paper'
        // ...(theme.palette.mode === 'light' ? { bgcolor: 'rgb(50,54,53)' } : {}),
        backgroundImage: 'none',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: { xs: 'column', md: 'row' },
      })}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flex="1 1 auto"
      >
        <Box
          component="img"
          src={`${theme.palette.mode === 'light' ? logoLightImage : logoImage}`}
          sx={{
            height: {
              xs: '190px',
              sm: '260px',
              md: '340px',
              lg: '430px',
              xl: '500px',
            },
          }}
        ></Box>
      </Box>
      <Box
        // sx={(theme) => ({
        //   ...theme.gradients.primary,
        // })}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer,
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          pb: 5,
          justifyContent: { xs: 'space-between', md: '' },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            justifyContent: { xs: 'center', md: 'start' },
            flex: { xs: '0 0 auto', md: '1 1 50%' },
          }}
        >
          <Link
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5548996657080"
          >
            <Box
              component="img"
              src={`${cliqueAquiImage}`}
              sx={{
                height: { xs: '90px', sm: '106px', lg: '132px', xl: '150px' },
                margin: {
                  xs: '',
                  md: '142px 0 0 -66px',
                  lg: '142px 0 0 -165px',
                  // xl: '142px 0 0 -285px',
                },
              }}
            ></Box>
          </Link>
        </Box>
        <Box
          sx={{
            flex: '0 0 auto',
            pr: { xs: 0, md: 4 },
            textAlign: { xs: 'center', md: 'end' },
            // justifyContent: { xs: 'center', md: '' },
          }}
        >
          <Box
            component="img"
            src={`${horarioAtendimentoImage}`}
            sx={{
              height: { xs: '50px', sm: '64px', lg: '70px', xl: '80px' },
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: { xs: '55%', sm: '58%', md: 0 },
          bottom: 0,
          right: 0,
          left: { xs: 0, md: '50%' },
          transform: {
            xs: 'rotate(0.46turn) scale(1.5) translateY(-53px)',
            md: 'skew(-15deg) translate(100px)',
          },
          background:
            'linear-gradient(113.04deg, rgb(0,156,104) 0%, rgb(15 215 150) 100%);',
        })}
      />
    </Paper>
  );
}

export default WhatsAppMensagensPage;
