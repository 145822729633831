import React from 'react';
import { useQuery } from '@apollo/client';
import { PATH } from '@constants';
import {
  Drawer,
  Box,
  IconButton,
  List,
  Grow,
  useTheme,
  useMediaQuery,
  DrawerProps,
} from '@mui/material';
import { menuMobileOpenedAtom, menuShrinkedAtom } from 'atoms/userPreferences';
import { CaretDown } from 'components/Icons';
import {
  COUNT_MENSAGENS_QUERY,
  NOTIFICACOES_QUERY,
} from 'Layout/AuthenticatedLayout/AuthenticatedLayout.graphql';
import { useRecoilState, useRecoilValue } from 'recoil';
import MenuItem from './MenuItem';
import MenuAoVivo from './MenuAoVivo';
import { isEventBarShowingAtom } from 'atoms/isEventBarShowing';

const MENU_WIDTH = 226;
const MENU_WIDTH_SHRINKED = 72;

export function useGetMenuWidth() {
  const shrinked = useRecoilValue(menuShrinkedAtom);
  return shrinked ? MENU_WIDTH_SHRINKED : MENU_WIDTH;
}

function Menu() {
  const [shrinked, setShrinked] = useRecoilState(menuShrinkedAtom);
  const theme = useTheme();
  const width = shrinked ? MENU_WIDTH_SHRINKED : MENU_WIDTH;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data: countMensagensPendentesData } = useQuery<{
    countMensagensPendentes: number;
  }>(COUNT_MENSAGENS_QUERY, { fetchPolicy: 'no-cache' });

  const { data: notificacoesData } = useQuery<{
    notificacoes: { id: string; visualizada: boolean }[];
  }>(NOTIFICACOES_QUERY, { fetchPolicy: 'no-cache' });

  return (
    <DrawerResponsive {...{ width }}>
      <Box display="flex" position={'relative'} height="142px">
        <Grow in={!isMobile && shrinked} unmountOnExit mountOnEnter>
          <Box
            sx={{
              position: 'absolute',
              left: -12,
              right: -12,
              top: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {theme.images?.logoShrinked}
          </Box>
        </Grow>
        <Grow in={isMobile || !shrinked} unmountOnExit mountOnEnter>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
            }}
          >
            {typeof theme.images?.logoImage === 'string' ? (
              <Box
                component="img"
                height={'125px'}
                maxWidth="130px"
                margin="auto"
                src={theme.images?.logoImage}
              />
            ) : (
              theme.images?.logoImage({
                color: '#CACACA',
                height: '42px',
                maxWidth: '130px',
                margin: 'auto',
              })
            )}
          </Box>
        </Grow>
      </Box>
      <IconButton
        sx={(theme) => ({
          ...theme.menu.shrinkButton,
          transform: shrinked ? 'rotate(-90deg)' : 'rotate(90deg)',
          transition: 'transform 0.2s cubic-bezier(0.28,-0.35, 0.78, 1.39)',
          ml: 'auto',
          display: { xs: 'none', md: 'flex' },
        })}
        onClick={() => setShrinked(!shrinked)}
      >
        <CaretDown fontSize="small" sx={{ height: '.8rem', width: '.8rem' }} />
      </IconButton>

      <List sx={{ backgroundColor: 'inherit' }} className="notZebra">
        {theme.menu.items.map((item) => {
          switch (item.id) {
            case 'MeusCursos':
              return (
                <MenuItem
                  text={item.text}
                  icon={item.icon}
                  path={PATH.cursos}
                  {...{ shrinked }}
                />
              );

            case 'Mensagens':
              return (
                <MenuItem
                  text={item.text}
                  icon={item.icon}
                  path={
                    theme.palette.tema === 'prodez'
                      ? PATH.whatsappMensagens
                      : PATH.mensagens
                  }
                  propsBadge={{
                    badgeContent: shrinked
                      ? 0
                      : countMensagensPendentesData?.countMensagensPendentes,
                  }}
                  {...{ shrinked }}
                />
              );

            case 'Alertas':
              return (
                <MenuItem
                  text={item.text}
                  icon={item.icon}
                  path={PATH.notificacoes}
                  propsBadge={{
                    // badgeContent: 5,
                    badgeContent: shrinked
                      ? 0
                      : notificacoesData?.notificacoes.filter(
                          (n) => !n.visualizada
                        ).length,
                  }}
                  {...{ shrinked }}
                />
              );

            case 'Noticias':
              return (
                <MenuItem
                  text={item.text}
                  icon={item.icon}
                  path={item.link || 'https://prodezconcursos.com.br/noticias'}
                  target="_blank"
                  rel="noopener noreferrer"
                  {...{ shrinked }}
                />
              );

            case 'AoVivo':
              return (
                <MenuAoVivo>
                  {({ propsBadge, className }) => (
                    <MenuItem
                      text={item.text}
                      icon={item.icon}
                      path={PATH.aovivo}
                      listItemButtonClassName={className}
                      {...{ shrinked, propsBadge }}
                    />
                  )}
                </MenuAoVivo>
              );

            case 'Produtos':
              return (
                <MenuItem
                  text={item.text}
                  icon={item.icon}
                  path="https://prodezconcursos.com.br/cursos"
                  target="_blank"
                  rel="noopener noreferrer"
                  {...{ shrinked }}
                />
              );

            case 'TodosCursos':
              return (
                <MenuItem
                  text={item.text}
                  icon={item.icon}
                  path="https://prodezconcursos.com.br/cursos"
                  target="_blank"
                  rel="noopener noreferrer"
                  {...{ shrinked }}
                />
              );

            case 'Simulados':
              return (
                <MenuItem
                  text={item.text}
                  icon={item.icon}
                  path="https://prodezconcursos.com.br/simulados"
                  target="_blank"
                  rel="noopener noreferrer"
                  {...{ shrinked }}
                />
              );

            default:
              return null;
          }
        })}
      </List>
    </DrawerResponsive>
  );
}

function DrawerResponsive(props: DrawerProps & { width: number }) {
  const [mobileOpened, setMobileOpened] = useRecoilState(menuMobileOpenedAtom);
  const isEventBarShowing = useRecoilValue(isEventBarShowingAtom);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpened}
        onClose={() => setMobileOpened(!mobileOpened)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          flex: '0 0 auto',
          width: MENU_WIDTH,
          '& .MuiDrawer-paper': (theme) => ({
            boxSizing: 'border-box',
            width: MENU_WIDTH,
            px: 1,
            overflow: 'hidden',
            backgroundImage: 'none',
            ...theme.menu.content,
          }),
        }}
      >
        {props.children}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          flex: '0 0 auto',
          width: props.width,
          transition: 'width 0.2s cubic-bezier(0.28,-0.35, 0.78, 1.39)',
          '& .MuiDrawer-paper': (theme) => ({
            top: isEventBarShowing ? '40px' : '0',
            boxSizing: 'border-box',
            width: props.width,
            transition: 'width 0.2s cubic-bezier(0.28,-0.35, 0.78, 1.39)',
            px: 2,
            overflow: 'hidden',
            ...theme.menu.content,
          }),
        }}
        open
      >
        {props.children}
      </Drawer>
    </>
  );
}

export default Menu;
